.container {
  display: flex;
  width: 100%;
  /* height: 100%; */
}

.title {
  color: #2f2f2f;
  flex: 1;
  font-size: 25px;
  margin-left: 25px;
  font-weight: 500;
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
}
