@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&family=Poppins:wght@400;500&display=swap");

.rdt_Table .rdt_TableHeadRow {
  /* TODO bg and color are same */
  background-color: #f9fafb;
  color: #6e7191;
}

* {
  font-family: "Poppins";
  font-weight: 400;
}

input:-webkit-autofill {
  -webkit-text-fill-color: black;
}

.poppins {
  font-family: "Poppins";
  font-weight: 400;
}
